import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPage from "./pages/landing/LandingPage";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/PrivateRoute";
import FullGallery from "./pages/fullGallery/FullGallery";
import Sidebar from "./admin/sidebar/Sidebar";
import Header from "./admin/Header/Header";
import Dashboard from "./admin/sidebar/pages/Dashboard";
import User from "./admin/sidebar/pages/User";
import Driver from "./admin/sidebar/pages/Driver/Driver";
import Subscriptions from "./admin/sidebar/pages/Subscriptions";
import SupportTicket from "./admin/sidebar/pages/SupportTicket";
import DriverPayout from "./admin/sidebar/pages/DriverPayout/DriverPayout";
import ContactDetail from "./admin/sidebar/pages/ContactDetail";
import DriverPayoutDetails from "./admin/sidebar/pages/DriverPayout/DriverPayoutDetail";
import PendingDriver from "./admin/sidebar/pages/Driver/PendingDriver";
import DriverDetails from "./admin/sidebar/pages/Driver/DriverDetails";
import ImageUpload from "./admin/sidebar/pages/ImageUpload";
import useScrollReset from "./ScrollReset/useScrollReset";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/allgallery" element={<FullGallery />} />
        <Route
          path="/admin/*"
          element={<PrivateRoute element={<AdminLayout />} />}
        />
      </Routes>
    </Router>
  );
};

// Admin Layout component
const AdminLayout: React.FC = () => {
  useScrollReset();
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col flex-grow">
        <Header adminName="Admin" />
        <div className="p-4">
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/admin/dashboard" replace />}
            />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<User />} />
            <Route path="drivers" element={<Driver />} />
            <Route
              path="drivers/pending-driver/:id"
              element={<PendingDriver />}
            />
            <Route
              path="drivers/driver-details/:id"
              element={<DriverDetails />}
            />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="support-tickets" element={<SupportTicket />} />
            <Route path="driver-payouts" element={<DriverPayout />} />
            <Route
              path="driver-payouts/:id"
              element={<DriverPayoutDetails />}
            />
            <Route path="contact-details" element={<ContactDetail />} />
            <Route path="Image-Upload" element={<ImageUpload />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
