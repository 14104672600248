import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { ReactComponent as CloseIcon } from "../../../assets/icons/ep_circle-close-filled.svg";
import { X } from "react-bootstrap-icons";
import {
  addPlan,
  updatePlan,
  deletePlan,
  setPlans,
} from "../../../redux/slices/subscriptionSlice";
import { Button } from "react-bootstrap";

import {
  Box,
  Alert,
  AlertTitle,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import Pagination from "../PaginationComp/Paginations";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";
import { number } from "zod";

interface UserProfile {
  id: number;
  name: string;
  email: string;
  plan: string;
  startdate: string;
  enddate: string;
}
interface DashboardStats {
  "Total Revenue"?: number;
  "Total Subscribers"?: number;
}
interface ApiResponse {
  success: boolean;
  data: {
    userProfile: UserProfile;
    stats: DashboardStats;
  };
}

interface SubscriptionPlanData {
  id: number;
  planName: string;
  duration: string;
  price: string;
  distanceCovered: string;
  journeysIncluded: string;
  ratePerExtraKM: string;
  accidentRecoveryFee: string;
  precipiceFee: string;
  banner: File | null;
}

// interface CreatePlanModalProps {
//   open: boolean;
//   onClose: () => void;
//   onSubmit: (planData: SubscriptionPlanData, banners: File[]) => void;
// }

const Subscriptions: React.FC = () => {
  // State
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile>({
    id: 0,
    name: "",
    email: "",
    plan: "",
    startdate: "",
    enddate: "",
  });
  const [dashboardStats, setDashboardStats] = useState<DashboardStats>({
    "Total Revenue": 0,
    "Total Subscribers": 0,
  });

  // redux
  const dispatch = useDispatch<AppDispatch>();
  const plans = useSelector((state: RootState) => state.subscriptions);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editingPlan, setEditingPlan] = useState<SubscriptionPlanData | null>(
    null
  );
  const [planData, setPlanData] = useState<SubscriptionPlanData>({
    id: 0,
    planName: "",
    duration: "",
    price: "",
    distanceCovered: "",
    journeysIncluded: "",
    ratePerExtraKM: "",
    accidentRecoveryFee: "",
    precipiceFee: "",
    banner: null,
  });
  const [banners, setBanners] = useState<File[]>([]);
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showError, setShowError] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [itemsPerPage] = useState(10);

  // New state for delete confirmation modal //
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<SubscriptionPlanData | null>(
    null
  );

  // APi call
  const fetchDashboardData = async () => {
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.getUserSubscription();

    try {
      setLoading(true);
      const response = await axios.get<ApiResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setUserProfile(response.data.data.userProfile);
        setDashboardStats(response.data.data.stats);
      }
      setError(null);
    } catch (error) {
      setError("Failed to fetch dashboard data");
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  useEffect(() => {
    const savedPlans = localStorage.getItem("plans");
    if (savedPlans) {
      dispatch(setPlans(JSON.parse(savedPlans)));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("plans", JSON.stringify(plans));
  }, [plans]);

  useEffect(() => {
    if (planData.banner) {
      if (planData.banner instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBannerPreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(planData.banner);
      } else if (typeof planData.banner === "string") {
        setBannerPreviewUrl(planData.banner);
      }
    } else {
      setBannerPreviewUrl(null);
    }
  }, [planData.banner]);

  const validateField = (name: string, value: string) => {
    if (!value || value.trim() === "") {
      return `${name} is required`;
    }
    if (
      [
        "duration",
        "price",
        "distanceCovered",
        "journeysIncluded",
        "ratePerExtraKM",
        "accidentRecoveryFee",
        "precipiceFee",
      ].includes(name)
    ) {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
        return `${name} must be a positive number`;
      }
    }
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlanData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };
  const isFormValid = () => {
    const requiredFields = [
      "planName",
      "duration",
      "price",
      "distanceCovered",
      "journeysIncluded",
      "ratePerExtraKM",
      "accidentRecoveryFee",
      "precipiceFee",
    ];

    return (
      requiredFields.every(
        (field) => planData[field as keyof typeof planData] && !errors[field]
      ) && !!planData.banner
    );
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) {
      setShowError(true);
      const newErrors: Record<string, string> = {};
      Object.keys(planData).forEach((field) => {
        const error = validateField(
          field,
          planData[field as keyof typeof planData] as string
        );
        if (error) newErrors[field] = error;
      });
      if (!planData.banner) {
        newErrors.banner = "Banner image is required";
      }
      setErrors(newErrors);
      return;
    }

    if (editingPlan) {
      dispatch(updatePlan({ ...planData, id: editingPlan.id }));
    } else {
      dispatch(addPlan({ ...planData, id: Date.now() }));
    }
    handleCloseModal();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    if (!editingPlan) {
      setBanners([]);
      setPlanData({
        id: 0,
        planName: "",
        duration: "",
        price: "",
        distanceCovered: "",
        journeysIncluded: "",
        ratePerExtraKM: "",
        accidentRecoveryFee: "",
        precipiceFee: "",
        banner: null,
      });
    } else {
      // If editing, set the banner from the existing plan
      setPlanData(editingPlan);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingPlan(null);
    setPlanData({
      id: 0,
      planName: "",
      duration: "",
      price: "",
      distanceCovered: "",
      journeysIncluded: "",
      ratePerExtraKM: "",
      accidentRecoveryFee: "",
      precipiceFee: "",
      banner: null,
    });
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();

  //   if (editingPlan) {
  //     dispatch(updatePlan({ ...planData, id: editingPlan.id }));
  //   } else {
  //     dispatch(addPlan({ ...planData, id: Date.now() }));
  //   }
  //   handleCloseModal();
  // };

  const handleBannerUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newBanner = e.target.files[0];
      setBanners([newBanner]);
      setPlanData((prevData) => ({ ...prevData, banner: newBanner }));
    }

    // for two render (bannar)

    // if (e.target.files) {
    //   const newBanners = Array.from(e.target.files);
    //   setBanners((prevBanners) =>{
    //     if(prevBanners.length >= 2){
    //       return [prevBanners[0],newBanners[0]]; // Keep first, replace second
    //     }
    //     return [...prevBanners, ...newBanners].slice(0,2);
    //   })
    // }
  };

  const handleRemoveBanner = () => {
    setPlanData((prevData) => ({ ...prevData, banner: null }));
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (plans.length < 4) {
  //     if (editingPlan) {
  //       setPlans(
  //         plans.map((plan) =>
  //           plan.id === editingPlan.id
  //             ? { ...planData, id: editingPlan.id }
  //             : plan
  //         )
  //       );
  //     } else {
  //       setPlans([...plans, { ...planData, id: Date.now() }]);
  //     }
  //   }
  //   // console.log("New Subscription Plan:", planData);
  //   // Here you would typically send this data to your backend
  //   handleCloseModal();
  // };

  const handleEdit = (plan: SubscriptionPlanData) => {
    setEditingPlan(plan);
    setPlanData(plan);
    setOpenModal(true);
  };

  // Modified delete handling ?? //

  // const handleDelete = (id: number) => {
  //   dispatch(deletePlan(id));
  // };

  const handleDeleteClick = (plan: SubscriptionPlanData) => {
    setPlanToDelete(plan);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (planToDelete) {
      dispatch(deletePlan(planToDelete.id));
      setDeleteModalOpen(false);
      setPlanToDelete(null);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setPlanToDelete(null);
  };

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

 // loding errror and making it more  to handel error
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ color: 'error.main', p: 2 }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }
  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "237px",
        marginTop: "38px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3.5, bgcolor: "background.default" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
            fontFamily: "Montserrat",
          }}
        >
          <Typography
            variant="h4"
            color="#2C3E50"
            style={{ fontFamily: "Montserrat" }}
          >
            Subscriptions
          </Typography>
          <Box sx={{ display: "flex", gap: 2, fontFamily: "Montserrat" }}>
            <Button
              variant="contained"
              style={{
                width: "18rem",
                height: "3rem",
                fontFamily: "Montserrat",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FDA214",
                color: "white",
                fontWeight: 300,
                fontSize: "1.1rem",
              }}
              onClick={handleOpenModal}
            >
              <AddIcon
                style={{ marginRight: "10px", fontFamily: "Montserrat" }}
              />
              Subscription Plan
            </Button>
            {/* <Button
              variant="outlined"
              style={{
                width: "17.5rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "orange",
                borderColor: "#FDA214",
                fontWeight: 300,
                fontSize: "1.1rem",
              }}
            >
              <img
                src={UploadIcon}
                alt="Upload Icon"
                width={30}
                height={30}
                style={{ marginRight: "10px" }}
              />
              Promotional Banner
            </Button> */}
          </Box>
        </Box>

        <Box sx={{ display: "flex", mb: 3, fontFamily: "Montserrat" }}>
          <Paper
            elevation={0}
            sx={{
              flex: 1,
              mr: 2,
              p: 2,
              background: "#F0F3FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              color="#345C72"
            >
              Total Revenue
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                color: "#345C72",
                fontFamily: "Montserrat",
              }}
            >
              $ {dashboardStats["Total Revenue"]?.toLocaleString()}
            </Typography>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              flex: 1,
              p: 2,
              background: "#F0F3FA",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              color="#345C72"
            >
              Total Subscribers
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                color: "#345C72",
                fontFamily: "Montserrat",
              }}
            >
              {dashboardStats["Total Subscribers"]?.toLocaleString()}
            </Typography>
          </Paper>
        </Box>

        {/* Subscription Plan Cards */}

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 3,
            flexWrap: "wrap",
            fontFamily: "Montserrat",
          }}
        >
          {plans.map((plan: SubscriptionPlanData) => (
            <Card
              key={plan.id}
              sx={{
                width: "calc(25% - 16px)",
                minWidth: 200,
                height: 120, // Fixed height for all cards
                backgroundColor: "#F0F3FA",
                boxShadow: "none", // Removes shadow
                border: "none", // Ensures no border
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                fontFamily: "Montserrat",
              }}
            >
              {/* <CardContent color="#345C72"> */}
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Montserrat",
                }}
                color="#345C72"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#345C72",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: 1.2,
                    maxHeight: "2.4em", // Allows for 2 lines of text
                    fontFamily: "Montserrat",
                  }}
                >
                  {plan.planName}
                </Typography>
              </CardContent>
              <CardActions>
                <MuiButton
                  size="small"
                  onClick={() => handleEdit(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      color: "white",
                      fontFamily: "Montserrat",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <Edit className="mr-2" width="16" />
                </MuiButton>
                <MuiButton
                  size="small"
                  onClick={() => handleDeleteClick(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      fontFamily: "Montserrat",
                      color: "white",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <Delete className="mr-2" width="16" />
                </MuiButton>
              </CardActions>
            </Card>
          ))}
        </Box>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Plan
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  End Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ fontFamily: "Montserrat" }}>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {userProfile.name}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {userProfile.email}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {userProfile.plan}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {userProfile.startdate}
                </TableCell>
                <TableCell style={{ fontFamily: "Montserrat" }}>
                  {userProfile.enddate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px", fontFamily: "Montserrat" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            fontFamily: "Montserrat",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 750, // Adjust as per requirement
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            "&::-webkit-scrollbar": {
              width: "8px", // Adjust the scrollbar width
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white", // Scrollbar track color
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "white", // Scrollbar thumb color
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "white", // Hover color for scrollbar thumb
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              {editingPlan ? "Edit Plan" : "Create Plan"}
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
          {showError && !isFormValid() && (
            <Alert severity="error" style={{ fontFamily: "Montserrat" }}>
              <AlertTitle style={{ fontFamily: "Montserrat" }}>
                Error
              </AlertTitle>
              Please fill in all required fields correctly before submitting.
            </Alert>
          )}
          <form
            onSubmit={handleSubmit}
            className="space-y-6 p-6"
            style={{ fontFamily: "Montserrat" }}
          >
            <Grid container spacing={1} style={{ fontFamily: "Montserrat" }}>
              <Grid item xs={6} md={6} style={{ fontFamily: "Montserrat" }}>
                <TextField
                  fullWidth
                  label="Plan Name"
                  name="planName"
                  value={planData.planName}
                  onChange={handleChange}
                  error={!!errors.planName}
                  helperText={errors.planName}
                  sx={{
                    mb: 2,
                    fontFamily: "Montserrat",
                    maxWidth: "300px",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        months
                      </InputAdornment>
                    ),
                  }}
                  style={{ fontFamily: "Montserrat" }}
                  placeholder="xyz"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Duration"
                  name="duration"
                  value={planData.duration}
                  onChange={handleChange}
                  error={!!errors.duration}
                  helperText={errors.duration}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <span
                        style={{
                          marginLeft: "8px",
                          color: "gray",
                        }}
                      >
                        months
                      </span>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  value={planData.price}
                  onChange={handleChange}
                  error={!!errors.price}
                  helperText={errors.price}
                  placeholder="300"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Distance Covered per Journey"
                  name="distanceCovered"
                  value={planData.distanceCovered}
                  onChange={handleChange}
                  error={!!errors.distanceCovered}
                  helperText={errors.distanceCovered}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">KM</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="No. of Journeys Included"
                  name="journeysIncluded"
                  value={planData.journeysIncluded}
                  onChange={handleChange}
                  error={!!errors.journeysIncluded}
                  helperText={errors.journeysIncluded}
                  placeholder="6"
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Rate per extra KM"
                  name="ratePerExtraKM"
                  value={planData.ratePerExtraKM}
                  onChange={handleChange}
                  error={!!errors.ratePerExtraKM}
                  helperText={errors.ratePerExtraKM}
                  placeholder="3000"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Accident Recovery Fee"
                  name="accidentRecoveryFee"
                  value={planData.accidentRecoveryFee}
                  onChange={handleChange}
                  error={!!errors.accidentRecoveryFee}
                  helperText={errors.accidentRecoveryFee}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Ravine/Ditch/Precipice Fee"
                  name="precipiceFee"
                  value={planData.precipiceFee}
                  onChange={handleChange}
                  error={!!errors.precipiceFee}
                  helperText={errors.precipiceFee}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Banner upload section */}

            <Box sx={{ mt: 3, mb: 3, width: "100%" }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, fontFamily: "Montserrat" }}
              >
                Uploaded Banners
              </Typography>

              <Box sx={{ width: "97%", fontFamily: "Montserrat" }}>
                {bannerPreviewUrl ? (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "33.33%", // 3:1 aspect ratio
                      height: 0,
                      overflow: "hidden",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <Box
                      component="img"
                      src={bannerPreviewUrl}
                      alt="Banner"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 1,
                        fontFamily: "Montserrat",
                      }}
                    />
                    <MuiButton
                      onClick={handleRemoveBanner}
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        minWidth: "auto",
                        p: 0.5,
                        bgcolor: "white",
                        color: "black",
                        borderRadius: "50%",
                        "&:hover": { bgcolor: "gray.100" },
                        fontFamily: "Montserrat",
                      }}
                    >
                      <CloseIcon
                        style={{
                          width: "1rem",
                          height: "1rem",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </MuiButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px dashed #B8B8B8",
                      borderRadius: 2,
                      fontFamily: "Montserrat",
                      p: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      minHeight: "100px",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        backgroundColor: "rgba(253, 162, 20, 0.04)",
                      },
                    }}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none", fontFamily: "Montserrat" }}
                      onChange={handleBannerUpload}
                      accept="image/jpeg,image/png,image/jpg"
                    />
                    <UploadIcon
                      style={{
                        width: "3rem",
                        height: "3rem",
                        fontFamily: "Montserrat",
                      }}
                    />
                    <Typography
                      sx={{ color: "#848484", fontFamily: "Montserrat" }}
                    >
                      Upload Banner (JPG, PNG or JPEG)
                    </Typography>
                    {errors.banner && (
                      <Typography
                        sx={{ mt: 2, color: "red", fontFamily: "Montserrat" }}
                      >
                        {errors.banner}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 2,
                fontFamily: "Montserrat",
              }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  color: "white",
                  fontFamily: "Montserrat",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "100px",
                }}
                disabled={!isFormValid()}
              >
                {editingPlan ? "Update" : "Create"}
              </MuiButton>
              <MuiButton
                onClick={handleCloseModal}
                variant="outlined"
                sx={{
                  background: "white",
                  fontFamily: "Montserrat",
                  color: "#F78409",
                  borderColor: "#F78409",
                  width: "120px",
                  height: "48px",
                  borderRadius: "24px",
                  border: "2px solid #F78409",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "medium",
                  boxShadow: "none",
                }}
              >
                Cancel
              </MuiButton>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-confirmation-dialog-title"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "24px",
            maxWidth: "400px",
            fontFamily: "Montserrat",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            fontFamily: "Montserrat",
          }}
        >
          <MuiButton
            onClick={handleDeleteCancel}
            sx={{
              minWidth: "auto",
              fontFamily: "Montserrat",
              p: 0,
              color: "#FDA214",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#FB923C",
                color: "white",
              },
            }}
          >
            <X />
          </MuiButton>
        </Box>
        <DialogTitle
          id="delete-confirmation-dialog-title"
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            color: "#2C3E50",
            textAlign: "center",
            paddingBottom: "8px",
          }}
        >
          {planToDelete ? ` ${planToDelete.planName}` : ""}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              color: "#345C72",
              marginBottom: "24px",
              fontFamily: "Montserrat",
            }}
          >
            Are you sure you want to delete this plan?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Montserrat",
            }}
          >
            <MuiButton
              onClick={handleDeleteConfirm}
              variant="contained"
              style={{
                backgroundColor: "#FDA214",
                color: "white",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              Delete
            </MuiButton>
            <MuiButton
              onClick={handleDeleteCancel}
              variant="outlined"
              sx={{
                color: "#FDA214",
                borderColor: "#FDA214",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                fontFamily: "Montserrat",
                "&:hover": {
                  backgroundColor: "transparent", // Removes background color on hover
                  borderColor: "#FDA214", // Keeps border color the same
                },
              }}
            >
              Cancel
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Subscriptions;
