import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { ReactComponent as RegisterdUser } from "../../../assets/user.svg";
import { ReactComponent as SubUser } from "../../../assets/icons/Approved.svg";
import { ReactComponent as Tickets } from "../../../assets/icons/help.svg";
import { ReactComponent as ApprovedDriver } from "../../../assets/icons/driverApp.svg";
import { ReactComponent as PendingDriver } from "../../../assets/icons/person.svg";
import { ReactComponent as DistanceCovered } from "../../../assets/icons/direction.svg";
import { ReactComponent as Revenue } from "../../../assets/icons/dollar.svg";
import { ReactComponent as Journeys } from "../../../assets/icons/distance.svg";
import { ReactComponent as Distance } from "../../../assets/icons/car.svg";
import DatabaseApi from "../../../services/database_api";


// Updated interfaces to match API response structure
interface ApiResponse<T> {
  status: string;
  message: string;
  data: T;
}
// Types for our API responses
interface DashboardStats {
  RegisterUsers?: number;
  SubscribedUsers?: number;
  "Pending Support Ticket"?: number;
  "Apporved Drivers"?: number;
  "Pending Drivers"?: number;
  "Total Distance Covered"?: number;
  "Total Revenue from Users"?: number;
  "Total Journeys"?: number;
  "Average Trip Distance"?: number;
}
interface SubscriptionSummary {
  name: string;
  email: string;
  plan: string;
  startdate: string;
  enddate: string;
}
interface PerformingDriver {
  name: string;
  complete_ride: number;
  earnings: number;
  distance_covered: number;
  activity_days: number;
}
interface StatCardProps {
  title: string;
  value: string;
  icon: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>; // Flexible type
}
const StatCard: React.FC<StatCardProps> = ({ title, value, icon }) => {
  // condtionaly render color value of card
  let valueColor = "#345C72";
  if (title === "Total Revenue from Users") {
    valueColor = "#1FA541";
  } else if (title === "Pending Support Tickets") {
    valueColor = "#C63708";
  }

  // Render the icon conditionally as an <img> tag or as an SVG component
  const renderIcon = () =>
    typeof icon === "string" ? (
      <img
        src={icon}
        alt={title}
        style={{ width: "30px", height: "30px", marginBottom: "10px" }}
      />
    ) : (
      React.createElement(icon, {
        style: { width: "30px", height: "30px", marginBottom: "10px" },
      })
    );

  return (
    <Card
      sx={{ backgroundColor: "#F0F3FA", boxShadow: "none", outline: "none", fontFamily: "Montserrat, sans-serif" }}
    >
      <CardContent>
        <div style={{ float: "right", fontFamily: "Montserrat" }}>{renderIcon()}</div>
        <Typography
          variant="h6"
          fontWeight="medium"
          color="#345C72"
          gutterBottom
          style={{ fontFamily: "Montserrat" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          fontWeight="bold"
          color={valueColor}
          component="div"
          style={{  fontFamily: "Montserrat" }}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Dashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardStats | null>(null);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionSummary[]>([]);
  const [driversData, setDriversData] = useState<PerformingDriver[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      console.log("Fetching data...");
      const apiUrl = DatabaseApi.getAdminDashboardUrl();
      const apiUrl2 = DatabaseApi.getSubscriptionSummaryUrl();
      const apiUrl3 = DatabaseApi.getPerformingDriversUrl();
      const token = localStorage.getItem("authToken");
      try {
        setLoading(true);
        // Fetching admin dashboard data
        const adminResponse = await axios.get<ApiResponse<DashboardStats>>(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Admin Data:", adminResponse.data);
        setDashboardData(adminResponse.data.data || {});

        // Fetching subscription summary data
        const subscriptionResponse = await axios.get<ApiResponse<SubscriptionSummary[]>>(
          apiUrl2,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Subscription Data:", subscriptionResponse.data);
        setSubscriptionData(subscriptionResponse.data.data || []);

        // Fetching performing drivers data
        const driversResponse = await axios.get<ApiResponse<PerformingDriver[]>>(apiUrl3, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Drivers Data:", driversResponse.data);
        setDriversData(driversResponse.data.data || []);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError("Failed to load dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const getSafeValue = (value: number | undefined): string => {
    return value?.toString() || "0";
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!dashboardData) return <Typography>No data available</Typography>;

  return (
    <div
      className="p-6 bg-white min-h-screen font-montserrat"
      style={{ maxWidth: "90%", marginLeft: "245px", marginTop: "70px" }}
    >
      <Typography variant="h4" gutterBottom color="#2C3E50" style={{  fontFamily: "Montserrat" }}>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Registered Users"
            value={getSafeValue(dashboardData.RegisterUsers)}
            icon={RegisterdUser}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Subscribed Users"
            value={getSafeValue(dashboardData.SubscribedUsers)}
            icon={SubUser}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Pending Support Tickets"
            value={getSafeValue(dashboardData["Pending Support Ticket"])}
            icon={Tickets}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Approved Drivers"
            value={getSafeValue(dashboardData["Apporved Drivers"])}
            icon={ApprovedDriver}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Pending Driver Accounts"
            value={getSafeValue(dashboardData["Pending Drivers"])}
            icon={PendingDriver}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Distance Covered"
            value={getSafeValue(dashboardData["Total Distance Covered"])}
            icon={DistanceCovered}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Revenue from Users"
            value={getSafeValue(dashboardData["Total Revenue from Users"])}
            icon={Revenue}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Journeys"
            value={getSafeValue(dashboardData["Total Journeys"])}
            icon={Journeys}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Average Trip Distance"
            value={getSafeValue(dashboardData["Average Trip Distance"])}
            icon={Distance}
          />
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        color="#2C3E50"
        gutterBottom
        style={{ marginTop: "60px", fontFamily: "Montserrat"  }}
        
      >
        Subscription Summary
      </Typography>
      <TableContainer component={Paper} elevation={0} color="#2C3E50">
        <Table>
          <TableHead sx={{ backgroundColor: "#F0F3FA"}} >
            <TableRow color="#2C3E50">
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" , fontFamily: "Montserrat"}}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" , fontFamily: "Montserrat"}}>Plan</TableCell>
              <TableCell sx={{ fontWeight: "bold" , fontFamily: "Montserrat"}}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" , fontFamily: "Montserrat"}}>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionData.map((subscriber, index) => (
              <TableRow key={index}>
                <TableCell style={{   fontFamily: "Montserrat"}}>{subscriber.name}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{subscriber.email}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{subscriber.plan}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{subscriber.startdate}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{subscriber.enddate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h5"
        color="#2C3E50"
        gutterBottom
        style={{ marginTop: "60px", fontFamily: "Montserrat" }}
        
      >
        Top Performing Drivers
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }} color="#2C3E50">
                Name
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" , fontFamily: "Montserrat"}} color="#2C3E50">
                Completed Rides
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }} color="#2C3E50">
                Earnings ($)
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }} color="#2C3E50">
                Distance Covered (km)
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontFamily: "Montserrat" }} color="#2C3E50">
                Active Days
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {driversData.map((driver, index) => (
              <TableRow key={index}>
                <TableCell style={{   fontFamily: "Montserrat"}}>{driver.name}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{driver.complete_ride}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{driver.earnings}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{driver.distance_covered}</TableCell>
                <TableCell style={{   fontFamily: "Montserrat"}}>{driver.activity_days}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Dashboard;
