class DatabaseApi {
  static mainUrl = "https://towing.shyapsneon.tech/api/";
  static adminLogin = `${this.mainUrl}AdminLogin`;

  // Method to fetch users by admin
  static getAllUserUrl(pageNumber: number, query: string) {
    const params = new URLSearchParams({
      page_number: pageNumber.toString(),
      search: query,
    });
    return `${this.mainUrl}show-all-user-by-admin?${params.toString()}`;
  }

  // Method to delete a user by admin
  static deleteUserUrl() {
    return `${this.mainUrl}delete-user-by-admin`;
  }

  // Method to get paginated URL for drivers
  static getDriversUrl(pageNumber: number, accountStatus?: string) {
    let url = `${this.mainUrl}show-all-driver-by-admin?page_number=${pageNumber}`;
    if (accountStatus) {
      url += `&account_status=${accountStatus}`;
    }
    return url;
  }

  // Method to get URL for searching drivers
  static searchDrivers(
    pageNumber: number,
    searchQuery: string,
    accountStatus?: string
  ) {
    let url = `${
      this.mainUrl
    }show-all-driver-by-admin?page_number=${pageNumber}&search=${encodeURIComponent(
      searchQuery
    )}`;
    if (accountStatus) {
      url += `&account_status=${accountStatus}`;
    }
    return url;
  }

  // Method to update driver account status
  static updateAccountStatusUrl() {
    return `${this.mainUrl}update-account-status`;
  }
  // Method to delete a driver by admin
  static deleteDriverUrl(driverId: number) {
    return `${this.mainUrl}delete-driver-by-admin/${driverId}`;
  }

  // SupportTicket

  // Method to fetch tickets by admin
  static getTicketsByAdminUrl(pageNumber: number, type: "driver" | "user") {
    return `${this.mainUrl}show-ticket-by-admin?page_number=${pageNumber}&type=${type}`;
  }
  // Method to get URL for searching tickets by admin
  static searchTickets(
    pageNumber: number,
    searchQuery: string,
    type: string = ""
  ) {
    return `${
      this.mainUrl
    }show-ticket-by-admin?page_number=${pageNumber}&type=${encodeURIComponent(
      type
    )}&search=${encodeURIComponent(searchQuery)}`;
  }
  // Method update Ticket Status Url by admin
  static updateTicketStatus(ticketId: string, status: string) {
    return `${this.mainUrl}update-status-by-admin`;
  }

  // Method to fetch data for the Admin Dashboard
  static getAdminDashboardUrl() {
    return `${this.mainUrl}Admin-Dashboard`;
  }

  // Method to fetch data for the Subscription-Summary
  static getSubscriptionSummaryUrl() {
    return `${this.mainUrl}Subscription-Summary`;
  }

  // Method to fetch data for the Performing-drivers
  static getPerformingDriversUrl() {
    return `${this.mainUrl}Performing-drivers`;
  }
  // Method to fetch data for the contect-details
  static getContectDetails() {
    return `${this.mainUrl}Admin-Details`;
  }
  //Method to update data for the contect-details
  static updateContectDetails() {
    return `${this.mainUrl}update-Admin-Details`;
  }

  // Method to update data for the user-Subscription
  static getUserSubscription() {
    return `${this.mainUrl}user-Subscription`;
  }
}

export default DatabaseApi;
