import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AdditionalInput {
  name: string;
  url: string;
}

interface AdditionalInputsState {
    items : AdditionalInput[];
}

const initialState: AdditionalInputsState = {
    items: [],
};

const additionalInputsSlice = createSlice({
    name: 'additionalInputs',
    initialState,
    reducers: {
        addInput: (state, action: PayloadAction<AdditionalInput>)=>{
            state.items.push(action.payload);
        }
    }
})

export const { addInput } = additionalInputsSlice.actions;
export default additionalInputsSlice.reducer;
