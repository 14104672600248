
import { configureStore } from '@reduxjs/toolkit';
import subscriptionReducer from './slices/subscriptionSlice';
import additionalInputsReducer from './slices/additionalInputsSlice';

export const store = configureStore({
  reducer: {
    subscriptions: subscriptionReducer,
    additionalInputs: additionalInputsReducer,
  },
  
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;