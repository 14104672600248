import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/Arrow-down.svg";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Fill 1.svg";
import { Gear, X } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
} from "@mui/material";
import Pagination from "../../PaginationComp/Paginations";

const tickets = [
  {
    id: "1",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
  },
  {
    id: "2",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
  },
  {
    id: "3",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "4",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "5",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "6",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "7",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "8",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "9",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "10",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "11",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "12",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "13",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "14",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-08-2024",
  },
  {
    id: "15",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
  },
  {
    id: "16",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
  },
  {
    id: "17",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-01-2024",
  },
  {
    id: "18",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-04-2024",
  },
  {
    id: "19",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-05-2024",
  },
  {
    id: "20",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-07-2024",
  },
  {
    id: "21",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-09-2024",
  },
  {
    id: "22",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
  },
  {
    id: "23",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
  },
];

interface tickest {
  [key: string]: string;
  ratePerKM: string;
  rateHillyKm: string;
  accidentRate: string;
  miscellaneousRate: string;
}

const SupportTicket: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activationStatus, setActivationStatus] = useState("Paid");
  const [openModal, setOpenModal] = useState(false);
  const [planData, setPlanData] = useState<tickest>({
    ratePerKM: "",
    rateHillyKm: "",
    accidentRate: "",
    miscellaneousRate: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showError, setShowError] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<{
    name: string;
    pendingAmount: string;
  } | null>(null);

  // row click model handel
  const handleRowClick = (driver: {
    id: string;
    name: string;
    pendingAmount: string;
    amountPaid: string;
    dateOfPayment: string;
  }) => {
    if (activationStatus === "Pending") {
      setSelectedDriver(driver);
    } else if (activationStatus === "Paid") {
      navigate(`/admin/driver-payouts/${driver.id}`, {
        state: {
          name: driver.name,
          amountPaid: driver.amountPaid,
          dateOfPayment: driver.dateOfPayment,
        },
      });
    }
  };
  const handleCloseDriverModal = () => {
    setSelectedDriver(null);
  };

  const handleMarkAsPaid = () => {
    console.log(`Marked as paid for ${selectedDriver?.name}`);
    handleCloseDriverModal();
  };
  //

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setPlanData({
      ratePerKM: "",
      rateHillyKm: "",
      accidentRate: "",
      miscellaneousRate: "",
    });
  };

  const validateField = (name: string, value: string) => {
    if (
      [
        "ratePerKM",
        "rateHillyKm",
        "accidentRate",
        "miscellaneousRate",
      ].includes(name)
    ) {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
        return `${name} must be a positive number`;
      }
    }
    return "";
  };

  // model inbox value handel
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlanData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isFormValid = () => {
    const requiredFields = [
      "ratePerKM",
      "rateHillyKm",
      "accidentRate",
      "miscellaneousRate",
    ];

    return requiredFields.every(
      (field) => planData[field as keyof typeof planData] && !errors[field]
    );
  };

  //handel submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) {
      setShowError(true);
      const newErrors: Record<string, string> = {};
      Object.keys(planData).forEach((field) => {
        const error = validateField(
          field,
          planData[field as keyof typeof planData] as string
        );
        if (error) newErrors[field] = error;
      });
      if (!planData.banner) {
        newErrors.banner = "Banner image is required";
      }
      setErrors(newErrors);
      return;
    }
  };

  // Filter drivers based on search query
  const filteredDrivers = tickets.filter((tickets) =>
    tickets.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //Pagination
  const [itemsPerPage] = useState(10); // Or any other number you prefer
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDrivers.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "40px" }}
    >
      <Box
        sx={{ p: 3.5, bgcolor: "background.paper", fontFamily: "Montserrat" }}
        color="#2C3E50"
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h4"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat" }}
          >
            Driver Payout
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              IconComponent={() => (
                <ArrowIcon
                  style={{
                    width: "3rem",
                    height: "3rem",
                    marginRight: "8px",
                    fontFamily: "Montserrat",
                  }}
                />
              )}
              sx={{
                width: 170,
                height: "3rem",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Custom border color on focus
                  boxShadow: "0 0 0 2px #FDA214", // Custom box shadow on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Optional: set border color on hover
                },
              }}
            >
              <MenuItem value="Paid" style={{ fontFamily: "Montserrat" }}>
                Paid
              </MenuItem>
              <MenuItem value="Pending" style={{ fontFamily: "Montserrat" }}>
                Pending
              </MenuItem>
            </Select>

            <div className="relative flex items-center">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{ width: "20px", height: "20px", color: "#FDA214", fontFamily: "Montserrat" }} // Adjust size and color if necessary
              />
              <Form.Control
                type="text"
                placeholder="Search Drivers by Name"
                className="pl-10 pr-6 py-2 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "20rem",
                  height: "3rem",
                  fontFamily: "Montserrat",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "left",
                  paddingLeft: "40px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <style>
                {`
                 .relative input::placeholder {
                 color: #B4BDC4; // Placeholder color
                  }
                `}
              </style>
              <Button
                variant="warning"
                className="ml-2 flex items-center justify-center"
                style={{
                  backgroundColor: "#FDA214",
                  color: "#fff",
                  fontFamily: "Montserrat",
                  borderRadius: "10px",
                  height: "3rem",
                  width: "10rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
                onClick={handleOpenModal}
              >
                <Gear size={18} />
                <span style={{fontFamily: "Montserrat"}}>Payout Rates</span>
              </Button>
            </div>
          </div>
        </div>

        <TableContainer component={Paper} elevation={0} style={{fontFamily: "Montserrat"}}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                  Driver Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                  Completed Trips
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                  Total Distance (km)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                  Accident Recovery
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                  Ravine / Ditch / Precipice
                </TableCell>
                {activationStatus === "Paid" ? (
                  <TableCell sx={{ fontWeight: "bold", padding: "16px" , fontFamily: "Montserrat"}}>
                    Amount Paid
                  </TableCell>
                ) : (
                  <TableCell sx={{ fontWeight: "bold", padding: "16px" ,fontFamily: "Montserrat"}}>
                    Pending Amount
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((ticket) => (
                <TableRow
                  key={ticket.id}
                  onClick={() =>
                    handleRowClick({
                      id: ticket.id,
                      name: ticket.name,
                      pendingAmount: ticket.pendingAmount,
                      amountPaid: ticket.amountPaid,
                      dateOfPayment: ticket.dateOfPayment,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    fontFamily: "Montserrat"
                  }}
                >
                  <TableCell style={{fontFamily: "Montserrat"}}>{ticket.name}</TableCell>
                  <TableCell style={{fontFamily: "Montserrat"}}>{ticket.completedTrips}</TableCell>
                  <TableCell style={{fontFamily: "Montserrat"}}>{ticket.totalDistance}</TableCell>
                  <TableCell style={{fontFamily: "Montserrat"}}>{ticket.accidentRecovery}</TableCell>
                  <TableCell style={{fontFamily: "Montserrat"}}>{ticket.ravineDitchPrecipice}</TableCell>
                  {activationStatus === "Paid" ? (
                    <TableCell style={{fontFamily: "Montserrat"}}>{ticket.amountPaid}</TableCell>
                  ) : (
                    <TableCell style={{fontFamily: "Montserrat"}}>{ticket.pendingAmount}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px",fontFamily: "Montserrat" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredDrivers.length / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            fontFamily: "Montserrat",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Adjust as per requirement
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              fontFamily: "Montserrat",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold" , fontFamily: "Montserrat"}}
            >
              Create Plan
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                fontFamily: "Montserrat",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          {showError && !isFormValid() && (
            <Alert severity="error" style={{fontFamily: "Montserrat"}}>
              <AlertTitle style={{fontFamily: "Montserrat"}}>Error</AlertTitle>
              Please fill in all required fields correctly before submitting.
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6 p-6">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Km"
                  name="ratePerKM" // Matches the key in planData
                  value={planData.ratePerKM}
                  onChange={handleChange}
                  error={!!errors.ratePerKM}
                  helperText={errors.ratePerExtraKM}
                  sx={{
                    mb: 2,
                    maxWidth: "230px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold", color: "#122E59", fontFamily: "Montserrat" }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Hilly Km"
                  name="rateHillyKm" // Matches the key in planData
                  value={planData.rateHillyKm}
                  onChange={handleChange}
                  error={!!errors.rateHillyKm}
                  helperText={errors.rateHillyKm}
                  sx={{
                    mb: 2,
                    maxWidth: "230px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold", color: "#122E59",fontFamily: "Montserrat" }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Accident Recovery Rate"
                  name="accidentRate" // Matches the key in planData
                  value={planData.accidentRate}
                  onChange={handleChange}
                  error={!!errors.accidentRate}
                  helperText={errors.accidentRate}
                  sx={{
                    mb: 2,
                    fontFamily: "Montserrat",
                    maxWidth: "230px",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold", color: "#122E59", fontFamily: "Montserrat" }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Miscellaneous Rate"
                  name="miscellaneousRate" // Matches the key in planData
                  value={planData.miscellaneousRate}
                  onChange={handleChange}
                  error={!!errors.miscellaneousRate}
                  helperText={errors.miscellaneousRate}
                  sx={{
                    mb: 2,
                    fontFamily: "Montserrat",
                    maxWidth: "230px",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold",color: "#122E59",fontFamily: "Montserrat"}}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 3, gap: 8, fontFamily: "Montserrat" }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  fontFamily: "Montserrat",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "150px",
                }}
                disabled={!isFormValid()}
              >
                Save Changes
              </MuiButton>
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                style={{
                  background: "white",
                  fontFamily: "Montserrat",
                  color: "#F78409",
                  borderColor: "#F78409",
                  width: "120px",
                  height: "48px",
                  borderRadius: "24px",
                  border: "2px solid #F78409",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "medium",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={!!selectedDriver && activationStatus === "Pending"}
        onClose={handleCloseDriverModal}
        aria-labelledby="driver-modal-title"
        aria-describedby="driver-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            fontFamily: "Montserrat",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 3,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", fontFamily: "Montserrat" }}>
            <MuiButton
              onClick={handleCloseDriverModal}
              sx={{
                minWidth: "auto",
                fontFamily: "Montserrat",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
          <Typography
            id="driver-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              fontFamily: "Montserrat",
              textAlign: "center",
              color: "#2C3E50",
              fontWeight: "bold",
            }}
          >
            {selectedDriver?.name}
          </Typography>
          <Typography
            id="driver-modal-description"
            sx={{ mt: 2, mb: 4, textAlign: "center", color: "#2C3E50", fontFamily: "Montserrat" }}
          >
            Amount to be paid
          </Typography>
          <Typography
            id="driver-modal-description"
            sx={{
              mt: 2,
              fontFamily: "Montserrat",
              mb: 4,
              textAlign: "center",
              color: "#2C3E50",
              fontWeight: "bold",
            }}
          >
            {selectedDriver?.pendingAmount}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" ,fontFamily: "Montserrat"}}>
            <MuiButton
              onClick={handleMarkAsPaid}
              variant="contained"
              sx={{
                bgcolor: "#FDA214",
                fontFamily: "Montserrat",
                color: "white",
                "&:hover": {
                  bgcolor: "#FB923C",
                },
                width: "200px",
                borderRadius: "20px",
              }}
            >
              Mark as Paid
            </MuiButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SupportTicket;
