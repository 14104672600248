// src/pages/Login/Login.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert, InputGroup } from "react-bootstrap";
import "../../main.css";
import DatabaseApi from "../../services/database_api";
import Routes from "../../services/routes";
// import "../../services/database_api";
// import "../../services/routes";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError(null);
    setSuccess(null);

    const apiUrl = DatabaseApi.adminLogin;
    
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // It's generally not recommended to hardcode Authorization headers for login
          // Removed Authorization header from login request
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Redirect to login if token is expired or unauthorized
          navigate(Routes.login);
          return;
        }
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (String(data.status) === "true") {
      
        const token = data.token; // Ensure the token is correctly received from the API

        // Optionally, decode the token to get expiration if it's a JWT
        // const decodedToken = JSON.parse(atob(token.split('.')[1]));
        // const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

        localStorage.setItem("authToken", token); // Save token in local storage

        setSuccess("Admin Login Successfully");
        console.log("Login success message set, navigating in 2 seconds...");

        // Navigate after a brief delay to show the success message
        setTimeout(() => {
          navigate("/admin/dashboard");
        });
      } else {
        setError(data.message || "Login failed");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("An error occurred while logging in");
    }
  };

  return (
    <Container
      className="login-page"
      style={{ maxWidth: "100%", maxHeight: "100%", marginTop: 0 }}
    >
      <div className="login-container">
        <h2 className="text-center mb-4">Login</h2>
        <Form onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}

          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <i className="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100">
            Login
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default Login;
