import React from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisibleButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxVisibleButtons) {
      // Show all pages if total pages are less than or equal to max visible buttons
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate start and end page numbers
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
      style={{ height: "100px" }}
    >
      <div className="d-flex justify-content-center mt-auto flex-wrap">
        <Button
          variant="outline-secondary"
          size="sm"
          className="mx-1 d-flex align-items-center justify-content-center"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
            color: currentPage > 1 ? "#fff" : "#6c757d",
            borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
            opacity: currentPage > 1 ? 1 : 0.5,
            cursor: currentPage > 1 ? "pointer" : "not-allowed",
          }}
        >
          <ChevronLeft className="mr-2" size={16} /> Previous
        </Button>

        {/* Page Numbers */}
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`w-10 h-10 flex items-center justify-center rounded-md mx-1 
              ${currentPage === pageNumber 
                ? 'bg-orange-500 text-white' 
                : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
          >
            {pageNumber}
          </button>
        ))}

        <Button
          variant="outline-secondary"
          size="sm"
          className="mx-1 d-flex align-items-center justify-content-center"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{
            backgroundColor: currentPage < totalPages ? "#FDA214" : "transparent",
            color: currentPage < totalPages ? "#fff" : "#6c757d",
            borderColor: currentPage < totalPages ? "#FDA214" : "#6c757d",
            opacity: currentPage < totalPages ? 1 : 0.5,
            cursor: currentPage < totalPages ? "pointer" : "not-allowed",
          }}
        >
          Next <ChevronRight className="ml-2" size={16} />
        </Button>
      </div>
    </div>
  );
};
export default Pagination;
