import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Use React Router's useLocation

const useScrollReset = () => {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Scroll to top when location changes (URL changes)
    window.scrollTo(0, 0);
  }, [location]); // Trigger the effect on location changes
};

export default useScrollReset;
