import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/Arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Fill 1.svg";

import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  DialogTitle,
  Menu,
  DialogContent,
  IconButton,
  TableBody,
  Grid,
  styled,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "../PaginationComp/Paginations";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";

interface Ticket {
  id: number;
  user_id: number | null;
  driver_id: string | null;
  ticket_no: string | null;
  subject: string;
  message: string;
  screenshot: string | null;
  other_attachments: string | null;
  status: string;
  department: string;
  created_at: string;
  updated_at: string;
  driver_name: string | null;
  user_name: string | null;
  user_email: string | null;
  driver_email: string | null;
}

// styled //
const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
}));

const Value = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
}));

const AttachmentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  height: 200,
}));

const SupportTicket: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState<Ticket | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState("");
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketType, setTicketType] = useState<"user" | "driver">("user");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [ticketId, setTicketId] = useState<string>("");

  const fetchTickets = async (
    pageNumber: number,
    type: "user" | "driver",
    searchQuery: string,
    ticketId: string
  ) => {
    setLoading(true);
    setError(null);

    const apiUrl = DatabaseApi.getTicketsByAdminUrl(pageNumber, type);
    const url = DatabaseApi.searchTickets(pageNumber, searchQuery, type);
    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.get(`${apiUrl}$search=${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const ticketData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setTickets(ticketData);
      setTotalPages(response.data.last_page || 1);
    } catch (error) {
      console.error("Error fetching tickets data:", error);
      setError("Failed to fetch tickets. Please try again.");
      setTickets([]);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (ticketId: number) => {
    setUpdatingStatus(true);
    const token = localStorage.getItem("authToken");

    try {
      const ResolveStatus = DatabaseApi.updateTicketStatus(
        ticketId.toString(),
        "Resolved"
      );

      const response = await axios.put(
        ResolveStatus,
        {
          id: ticketId.toString(),
          status: "Resolved",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        // Update the local state to reflect the change
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.id === ticketId ? { ...ticket, status: "Resolved" } : ticket
          )
        );

        // If the current selected ticket is the one being updated, update it too
        if (selectedUser?.id === ticketId) {
          setSelectedUser((prev) =>
            prev ? { ...prev, status: "Resolved" } : null
          );
        }

        // Close the modal after successful update
        setShowModal(false);

        // Refresh the tickets list to get updated data
        fetchTickets(currentPage, ticketType, searchQuery, ticketId.toString());

        // Show success message
        alert("Ticket status updated successfully!");
      } else {
        throw new Error("Failed to update ticket status");
      }
    } catch (error) {
      console.error("Error updating ticket status:", error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  // Debounce search to prevent too many API calls
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // Wait 500ms after last keystroke before searching
    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    fetchTickets(currentPage, ticketType, debouncedSearchQuery, ticketId);
  }, [currentPage, ticketType, debouncedSearchQuery, sortOrder, ticketId]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSortSelection = (order: "asc" | "desc") => {
    setSortOrder(order);
    handleCloseMenu();
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Update the placeholder text based on ticket type
  const getSearchPlaceholder = () => {
    return ticketType === "driver"
      ? "Search Drivers by Name"
      : "Search Users by Name";
  };

  //Pagination
  const [itemsPerPage] = useState(10); // Or any other number you prefer
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tickets.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "237px",
        marginTop: "39px",
        fontFamily: "Montserrat",
      }}
    >
      <Box
        sx={{ p: 3.5, bgcolor: "background.paper", fontFamily: "Montserrat" }}
        color="#2C3E50"
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h4"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat" }}
          >
            Support Tickets
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              style={{ fontFamily: "Montserrat" }}
              value={ticketType}
              onChange={(e) =>
                setTicketType(e.target.value as "user" | "driver")
              }
              IconComponent={() => (
                <ArrowIcon
                  style={{
                    fontFamily: "Montserrat",
                    width: "3rem",
                    height: "3rem",
                    marginRight: "8px",
                  }}
                />
              )}
              sx={{
                width: 170,
                height: "3rem",
                fontFamily: "Montserrat",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Custom border color on focus
                  boxShadow: "0 0 0 2px #FDA214", // Custom box shadow on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Optional: set border color on hover
                },
              }}
            >
              <MenuItem value="user" style={{fontFamily: "Montserrat"}}>User Tickets</MenuItem>
              <MenuItem value="driver" style={{fontFamily: "Montserrat"}}>Driver Tickets</MenuItem>
            </Select>

            <div className="relative ">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{ width: "20px", height: "20px", color: "#FDA214",fontFamily: "Montserrat" }} // Adjust size and color if necessary
              />
              <Form.Control
                type="text"
                placeholder={getSearchPlaceholder()}
                className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "30rem",
                  fontFamily: "Montserrat",
                  height: "3.5rem",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }} // Custom width and height
              />
              <style>
                {`
                  .relative input::placeholder {
                  color: #B4BDC4; // Placeholder color
                  }
                 `}
              </style>
            </div>
          </div>
        </div>

        {loading && <Typography style={{fontFamily: "Montserrat"}}>Loading...</Typography>}
        {error && <Typography color="error" style={{fontFamily: "Montserrat"}}>{error}</Typography>}

        {!loading && !error && (
          <TableContainer component={Paper} elevation={0} style={{fontFamily: "Montserrat"}}>
            <Table>
              <TableHead sx={{ backgroundColor: "#F0F3FA" ,fontFamily: "Montserrat"}}>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}>
                    Ticket No.
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}>
                    First Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}>
                    Department
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", py: 1 , fontFamily: "Montserrat" }}>
                    Subject
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold", py: 1 , fontFamily: "Montserrat"}}>
                    Date Created
                    <IconButton onClick={handleOpenMenu} size="small">
                      <KeyboardArrowDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleSortSelection("asc")} style={{fontFamily: "Montserrat"}}>
                        Earliest
                      </MenuItem>
                      <MenuItem onClick={() => handleSortSelection("desc")} style={{fontFamily: "Montserrat"}}>
                        Latest
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems
                  .filter(
                    (ticket) =>
                      (ticketType === "driver" && ticket.driver_id) ||
                      (ticketType === "user" && ticket.user_id)
                  )
                  .map((ticket) => (
                    <TableRow
                      key={ticket.id}
                      onClick={() => {
                        setSelectedUser(ticket);
                        setShowModal(true);
                      }}  
                      style={{
                        fontFamily: "Montserrat",
                        cursor: "pointer",
                        backgroundColor:
                          hoveredRow === ticket.id ? "#f0f0f0" : "transparent",
                      }}
                      onMouseEnter={() => setHoveredRow(ticket.id)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <TableCell sx={{ py: 2,fontFamily: "Montserrat" }}>
                        {ticket.ticket_no || "N/A"}
                      </TableCell>
                      <TableCell sx={{ py: 2,fontFamily: "Montserrat" }}>
                        {ticketType === "driver"
                          ? ticket.driver_name
                          : ticket.user_name}
                      </TableCell>
                      <TableCell sx={{ py: 2, fontFamily: "Montserrat"}}>{ticket.department}</TableCell>
                      <TableCell sx={{ py: 2, fontFamily: "Montserrat"}}>{ticket.subject}</TableCell>
                      <TableCell align="right" sx={{ padding: "40px", py: 2, fontFamily: "Montserrat" }}>
                        {new Date(ticket.created_at)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace(/\//g, "-")
                          .toUpperCase()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px", fontFamily: "Montserrat" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        {selectedUser && (
          <Modal show={showModal} onHide={handleCloseMenu} size="lg">
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" color="#2C3E50" style={{fontFamily: "Montserrat"}}>
                  Ticket Details
                </Typography>
                <IconButton onClick={handleCloseModal} size="small">
                  <CloseIcon sx={{ color: "orange" }} />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}> 
                <Grid item xs={6}>
                  <Label style={{ color: "#4D667C" ,fontFamily: "Montserrat"}}>
                    Full Name :{" "}
                    {ticketType === "user"
                      ? selectedUser.user_name
                      : selectedUser.driver_name}
                  </Label>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Label style={{ color: "#212830", fontWeight: "bold" , fontFamily: "Montserrat"}}>
                    email:{" "}
                    {ticketType === "user"
                      ? selectedUser.user_email
                      : selectedUser.driver_email}
                  </Label>
                </Grid>
                <Grid item xs={4}>
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat" }}>
                    Ticket No:
                    {selectedUser.ticket_no}
                  </Label>
                </Grid>
                <Grid item xs={4}>
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat" }}>
                    Department: {selectedUser.department}
                  </Label>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat"}}>Status :</Label>
                  <Value sx={{ color: "orange", fontWeight: "medium" , fontFamily: "Montserrat"}}>
                    {selectedUser.status}
                  </Value>
                </Grid>
                <Grid item xs={12}>
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat" }}>Subject :</Label>
                  <Value style={{ color: "#4D667C", fontFamily: "Montserrat" }}>
                    {selectedUser.subject}
                  </Value>
                </Grid>
                <Grid item xs={12}>
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat" }}>Message :</Label>
                  <Value
                    sx={{
                      color: "#4D667C",
                      lineHeight: 1.5,
                      fontFamily: "Montserrat",
                      whiteSpace: "normal", // Allows the text to wrap
                      wordWrap: "break-word", // Breaks long words to prevent overflow
                    }}
                  >
                    {selectedUser.message}
                  </Value>
                </Grid>
                <Grid item xs={12}>
                  <Label style={{ color: "#4D667C", fontFamily: "Montserrat" }}>Attachments:</Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ color: "#344054", fontWeight: "bold", fontFamily: "Montserrat" }}
                  >
                    Screenshot
                  </Typography>
                  <AttachmentBox />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ color: "#344054", fontWeight: "bold", fontFamily: "Montserrat" }}
                  >
                    Other attachments
                  </Typography>
                  <AttachmentBox />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  {selectedUser.status !== "Resolved" && (
                    <Button
                      disabled={updatingStatus}
                      onClick={() => handleStatusUpdate(selectedUser.id)}
                      style={{
                        marginTop: "16px",
                        backgroundColor: updatingStatus ? "#ccc" : "orange",
                        paddingLeft: "16px", //
                        paddingRight: "16px",
                        outline: "none",
                        fontFamily: "Montserrat",
                        border: "none",
                      }}
                      onMouseEnter={(e) => {
                        if (!updatingStatus) {
                          (
                            e.target as HTMLButtonElement
                          ).style.backgroundColor = "#F78409";
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (!updatingStatus) {
                          (
                            e.target as HTMLButtonElement
                          ).style.backgroundColor = "orange";
                        }
                      }}
                    >
                      {updatingStatus ? "Updating" : "Mark as Resolved"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Modal>
        )}
      </Box>
    </div>
  );
};

export default SupportTicket;
