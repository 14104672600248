import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";

interface ContactDetails {
  website: string;
  address: string;
  phone: string;
  whatsapp: string;
  email: string;
  instagram: string;
  x_twitter: string;
  facebook: string;
}

const defaultContactDetails: ContactDetails = {
  website: "",
  address: "",
  phone: "",
  whatsapp: "",
  email: "",
  instagram: "",
  x_twitter: "",
  facebook: "",
};

const ContactDetails = () => {
  const [contactDetail, setContactDetails] = useState<ContactDetails>(
    defaultContactDetails
  );
  const [initialValues, setInitialValues] = useState<ContactDetails>(
    defaultContactDetails
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [pendingNavigationAction, setPendingNavigationAction] = useState<
    (() => void) | null
  >(null);

  const fetchContactData = async () => {
    const apiUrl = DatabaseApi.getContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        const fetchedData = response.data.data;

        // Ensure all fields have at least empty string values
        const sanitizedData = {
          website: fetchedData.website || "",
          address: fetchedData.address || "",
          phone: fetchedData.phone || "",
          whatsapp: fetchedData.whatsapp || "",
          email: fetchedData.email || "",
          instagram: fetchedData.instagram || "",
          x_twitter: fetchedData.x_twitter || "",
          facebook: fetchedData.facebook || "",
        };

        console.log("Fetched data:", sanitizedData);
        setContactDetails(sanitizedData);
        setInitialValues(sanitizedData);
      } else {
        setError("No data available.");
      }
      setError(null);
    } catch (error) {
      setError("Failed to fetch contact details. Please try again later.");
      console.error("Error fetching contact details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    const newDetails = {
      ...contactDetail,
      [name]: value,
    };

    const hasChanges = Object.keys(newDetails).some(
      (key) =>
        newDetails[key as keyof ContactDetails] !==
        initialValues[key as keyof ContactDetails]
    );
    setHasUnsavedChanges(hasChanges);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const apiUrl = DatabaseApi.updateContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.put(apiUrl, contactDetail, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setInitialValues(contactDetail);
        setHasUnsavedChanges(false);
        setSuccessMessage("Contact details updated successfully!");
        setTimeout(() => setSuccessMessage(null), 2000);
      }
    } catch (error) {
      setError("Failed to update contact details. Please try again.");
      setTimeout(() => setError(null), 3000);
      console.error("Error updating contact details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle navigation attempts
  const handleNavigationAttempt = (navigationAction: () => void) => {
    if (hasUnsavedChanges) {
      setShowExitDialog(true);
      setPendingNavigationAction(() => navigationAction);
    } else {
      navigationAction();
    }
  };
  // Function to handle saving and then navigating
  const handleSaveAndExit = async () => {
    const apiUrl = DatabaseApi.updateContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.put(apiUrl, contactDetail, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        if (pendingNavigationAction) {
          pendingNavigationAction();
        }
        setShowExitDialog(false);
      }
    } catch (err) {
      setError("Failed to save changes. Please try again.");
      setTimeout(() => setError(null), 3000);
      console.error("Error saving changes:", err);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle discarding changes and navigating
  const handleDontSave = () => {
    if (pendingNavigationAction) {
      pendingNavigationAction();
    }
    setShowExitDialog(false);
  };

  if (loading && !contactDetail.website) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "246px",
        marginTop: "43px",
        fontFamily: "Montserrat",
      }}
    >
      <Container className="py-6">
        <Typography
          variant="h4"
          gutterBottom
          color="#2C3E50"
          style={{ fontFamily: "Montserrat" }}
        >
          Contact Details
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="website">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Website
                </Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={contactDetail.website}
                  onChange={handleInputChange}
                  placeholder="Enter website URL"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="address">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={contactDetail.address}
                  onChange={handleInputChange}
                  placeholder="Enter address"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Telephone
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={contactDetail.phone}
                  onChange={handleInputChange}
                  placeholder="Enter telephone number"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="whatsapp">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Whatsapp
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="whatsapp"
                  value={contactDetail.whatsapp}
                  onChange={handleInputChange}
                  placeholder="Enter WhatsApp number"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={contactDetail.email}
                  onChange={handleInputChange}
                  placeholder="Enter email address"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="instagram">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Instagram
                </Form.Label>
                <Form.Control
                  type="text"
                  name="instagram"
                  value={contactDetail.instagram}
                  onChange={handleInputChange}
                  placeholder="Enter Instagram handle"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="x_twitter">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  X (Formerly Twitter)
                </Form.Label>
                <Form.Control
                  style={{ fontFamily: "Montserrat" }}
                  type="text"
                  name="x_twitter"
                  value={contactDetail.x_twitter}
                  onChange={handleInputChange}
                  placeholder="Enter X handle"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="facebook">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Facebook
                </Form.Label>
                <Form.Control
                  style={{ fontFamily: "Montserrat" }}
                  type="text"
                  name="facebook"
                  value={contactDetail.facebook}
                  onChange={handleInputChange}
                  placeholder="Enter Facebook URL"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center gap-10 mt-14">
            <Button
              style={{
                backgroundColor: "#FDA214",
                color: "#FFFFFF",
                borderColor: "transparent", // Sets border color directly
                fontFamily: "Montserrat",
              }}
              type="submit" // Change button type to avoid form submission
              className="px-4 no-focus-outline"
              disabled={!hasUnsavedChanges}
            >
              Update
            </Button>
          </div>
        </Form>
        <Dialog
          open={showExitDialog}
          onClose={() => setShowExitDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Changes are not saved. Are you sure you want to exit page?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <MuiButton
              onClick={() => setShowExitDialog(false)}
              variant="outlined"
            >
              Cancel
            </MuiButton>
            <MuiButton
              onClick={handleDontSave}
              variant="contained"
              color="error"
            >
              Don't Save
            </MuiButton>
            <MuiButton
              onClick={handleSaveAndExit}
              variant="contained"
              color="success"
              autoFocus
            >
              Save and Exit
            </MuiButton>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};
export default ContactDetails;
