import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PersonDash } from "../../../../assets/icons/flowbite_user-remove-outline.svg";
import { ReactComponent as GreenIcon } from "../../../../assets/icons/Greeen.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Fill 1.svg";

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/Arrow-down.svg";
import { Form, Button } from "react-bootstrap";
import {
  Box,
  Typography,
  Select,
  Table,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Pagination from "../../PaginationComp/Paginations";
import axios from "axios";
import DatabaseApi from "../../../../services/database_api";

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password?: string;
  account_type?: string;
  company_name?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  license_document?: string | null;
  towing_license?: string | null;
  insurance_document?: string | null;
  upload_company_document?: string | null;
  account_status?: string;
  created_at: string;
  updated_at: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

interface ApiResponse {
  data: Driver[];
  total: number; // Total number of records from server
  current_page: number;
  per_page: number;
}

const DriverComponent: React.FC = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]); // State to hold drivers data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedUser, setSelectedUser] = useState<Driver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [activationStatus, setActivationStatus] = useState("Activate");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState("");
  const navigate = useNavigate();
  const StatusIcon = activationStatus === "Activate" ? PersonDash : GreenIcon;

  // Fetch drivers data from API

  const fetchDrivers = async (pageNumber: number, searchQuery: string) => {
    const apiUrl = searchQuery
      ? DatabaseApi.searchDrivers(pageNumber, searchQuery, activationStatus)
      : DatabaseApi.getDriversUrl(pageNumber, activationStatus);
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const driverData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setDrivers(driverData); // Set drivers data to be an array
      setTotalRecords(response.data.total || 1);
    } catch (error) {
      console.error("Error fetching drivers data:", error);
      setDrivers([]);
      setTotalRecords(1);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDrivers(newPage, searchQuery);
  };

  // handle Menu
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSortSelection = (order: string) => {
    setSortOrder(order);
    handleCloseMenu();
  };

  // row click model handel
  const handleRowClick = (driver: Driver) => {
    const path =
      driver.account_status === "Pending"
        ? `/admin/drivers/pending-driver/${driver.id}`
        : `/admin/drivers/driver-details/${driver.id}`;
    navigate(path, { state: { driver } });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 for new search results
    fetchDrivers(1, e.target.value);
  };

  // const handleStatusChange = (e: React.ChangeEvent<{ value: unknown }>) => {
  //   const newStatus = e.target.value as string;
  //   setActivationStatus(newStatus);
  //   setCurrentPage(1); // Reset to first page when changing status
  //   fetchDrivers(1, searchQuery);
  // };

  // Adjust useEffect to fetch data based on currentPage
  useEffect(() => {
    fetchDrivers(currentPage, searchQuery);
  }, [currentPage, searchQuery, activationStatus]);

  // [activationStatus]); // Only re-fetch when activation status changes

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div
      className="p-6 bg-white min-h-screen "
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "45px" }}
    >
      <Box sx={{ p: 3 }}>
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h4"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat" }}
          >
            Drivers
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              IconComponent={() => (
                <ArrowIcon
                  style={{
                    width: "3rem",
                    height: "3rem",
                    marginRight: "8px",
                    backgroundImage: `url(${ArrowIcon})`,
                    backgroundSize: "cover",
                    color: "#FDA214",
                  }}
                />
              )}
              sx={{
                width: 170,
                height: "3rem",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px", // Remove the border
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Custom border color on focus
                  boxShadow: "0 0 0 2px #FDA214", // Custom box shadow on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Optional: set border color on hover
                },
              }}
            >
              <MenuItem value="Activate" style={{ fontFamily: "Montserrat" }}>
                Activated
              </MenuItem>
              <MenuItem value="Pending" style={{ fontFamily: "Montserrat" }}>
                Pending Review
              </MenuItem>
            </Select>

            <div className="relative">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#FDA214",
                  fontFamily: "Montserrat",
                }}
              />

              <Form.Control
                type="text"
                placeholder="Search Users by Name / Email / Phone No."
                className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "30rem",
                  fontFamily: "Montserrat",
                  height: "3.5rem",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <style>
                {`
                 .relative input::placeholder {
                 color: #B4BDC4; // Placeholder color
                  }
                `}
              </style>
            </div>
          </div>
        </div>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Driver Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Phone no.
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    padding: "30px",
                    fontWeight: "bold",
                    py: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  Date Joined
                  <IconButton onClick={handleOpenMenu} size="small">
                    <KeyboardArrowDown />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem
                      onClick={() => handleSortSelection("Earliest")}
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Earliest
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleSortSelection("Latest")}
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Latest
                    </MenuItem>
                  </Menu>
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.map((driver) => (
                <TableRow
                  key={driver.id}
                  onClick={() => handleRowClick(driver)}
                  style={{
                    cursor: "pointer",
                    fontFamily: "Montserrat",
                    backgroundColor:
                      hoveredRow === driver.id ? "#f0f0f0" : "transparent",
                  }}
                  onMouseEnter={() => setHoveredRow(driver.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell
                    sx={{ py: 0.5, fontFamily: "Montserrat" }}
                  >{`${driver.first_name} ${driver.last_name}`}</TableCell>
                  <TableCell
                    sx={{
                      color: "#93310D",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textDecorationColor: "#93310D",
                      py: 0.5,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {driver.phone}
                  </TableCell>
                  <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                    {driver.email}
                  </TableCell>
                  <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                    {new Date(driver.created_at)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                      .toUpperCase()}
                  </TableCell>

                  <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                    <Button
                      variant="link"
                      className="text-gray-500 hover:text-gray-700 p-1"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <StatusIcon
                        className="ml-2"
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundSize: "cover",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
    </div>
  );
};

export default DriverComponent;
