import React from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/solar_letter-broken.svg";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as Pdf } from "../../../../assets/icons/Pdf.svg";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import DatabaseApi from "../../../../services/database_api";

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password?: string;
  account_type?: string;
  company_name?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  license_document?: string | null;
  towing_license?: string | null;
  insurance_document?: string | null;
  upload_company_document?: string | null;
  account_status?: string;
  created_at: string;
  updated_at: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const getDriverDocuments = (driver: Driver) => [
  {
    name: "Driving License",
    file: driver.license_document || null,
  },
  {
    name: "Towing License",
    file: driver.towing_license || null,
  },
  {
    name: "Insurance Document",
    file: driver.insurance_document || null,
  },
  {
    name: "Company Document",
    file: driver.upload_company_document || null,
  },
];

export default function PendingDriver() {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;

  if (!driver) {
    return <div>No driver data available</div>;
  }
  const updateAccountStatus = async (driverId: number, status: string) => {
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.updateAccountStatusUrl();
    try {
      const response = await axios.put(
        apiUrl,
        {
          driver_id: driverId,
          account_status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Update response:", response.data);
      navigate("/admin/drivers");
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const handleApprove = () => {
    const newStatus = driver.account_status === "Pending" ? "Activate" : "";
    updateAccountStatus(driver.id, newStatus);
    navigate("/admin/drivers");
  };

  const handleReject = () => {
    // Implement rejection logic here
    console.log("Driver rejected");
    navigate("/admin/drivers");
  };

  //pdf view
  const driverDocuments = getDriverDocuments(driver);

  const handleDocumentClick = (fileUrl: string | null) => {
    if (fileUrl) {
      // If the URL is relative, prepend your API base URL
      const fullUrl = fileUrl.startsWith('http') 
        ? fileUrl 
        : `${process.env.REACT_APP_API_URL}${fileUrl}`;
      window.open(fullUrl, '_blank');
    }
  };

  return (
    <Box className="bg-white p-8 md:p-24 min-h-screen lg:ml-48 ">
      <div className="max-w-7xl mx-auto">
        <Link
          to="/admin/drivers"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CustomLeftArrow
            style={{ width: 45, height: 55, marginRight: 10, marginBottom: 10 }}
          />
          <Typography variant="h4" gutterBottom color="#2C3E50" style={{fontFamily: "Montserrat"}}>
            Driver Pending Approval
          </Typography>
        </Link>

        <div className="flex items-center justify-between">
          <div className="flex items-center mb-7">
            <img
              src={`https://i.pravatar.cc/150?u=${driver.id}`}
              alt={`${driver.first_name} ${driver.last_name}`}
              className="w-20 h-20 rounded-full mr-4"
            />
            <h2 className="text-2xl font-semibold">{`${driver.first_name} ${driver.last_name}`}</h2>
          </div>
          <div className="ml-auto flex space-x-2">
            <Button variant="success" onClick={handleApprove}>
              Approve Driver
            </Button>
            <Button variant="danger" onClick={handleReject}>
              Reject Driver
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          {/* First Row */}
          <Form.Group className="relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
              className="z-10"
            >
              Phone Number
            </Form.Label>
            <PhoneIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.phone}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Email
            </Form.Label>
            <EmailIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="email"
              value={driver.email}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Vehicle Type
            </Form.Label>
            <VehicleIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.vehicle_type || "N/A"}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>

          {/* Second Row */}
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              License Plate Number
            </Form.Label>
            <LicensePlateIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.license_plate_number || "N/A"}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Account Type
            </Form.Label>
            <Form.Control
              type="text"
              value={driver.account_type || "N/A"}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Company Name
            </Form.Label>
            <Form.Control
              type="text"
              value={driver.company_name || "N/A"}
              readOnly
            />
          </Form.Group>
        </div>

        <div className="mt-6 space-y-4">
          {/* Third Row */}
          <div className="grid grid-cols-3 gap-4">
            {driverDocuments.map((doc, index) => (
              <div key={index} className="flex flex-col items-start w-full">
                <p
                  className="font-medium"
                  style={{
                    marginBottom: "0.0rem",
                    position: "relative",
                    top: "0.1rem",
                  }}
                >
                  {doc.name}
                </p>

                {doc.file ? (
                  <div
                    className="flex items-center border p-4 rounded cursor-pointer w-full"
                    style={{ height: "50px" }}
                    onClick={() => handleDocumentClick(doc.file)}
                  >
                    <Pdf className="w-8 h-8 mr-3" />
                    <p className="text-sm text-gray-500">
                      {doc.file.split("/").pop() || "document.pdf"}
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex items-center border p-4 rounded w-full bg-gray-50"
                    style={{ height: "50px" }}
                  >
                    <Pdf className="w-8 h-8 mr-3 text-gray-400" />
                    <p className="text-sm text-gray-400">
                      No document uploaded
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
}
