import React, { useState, useEffect } from "react";
import axios from "axios"; //  axios for API requests
import { ReactComponent as PersonDash } from "../../../assets/icons/flowbite_user-remove-outline.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/solar_letter-broken.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Fill 1.svg";
import { ReactComponent as ColorIcon } from "../../../assets/icons/fluent_color-24-regular.svg";
import { ReactComponent as StatusIcon } from "../../../assets/icons/material-symbols-light_paid-outline.svg";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  CircularProgress,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "../PaginationComp/Paginations";
import DatabaseApi from "../../../services/database_api";

// Interface for driver data from the API
interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  created_at: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  account_status?: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

interface ApiResponse {
  data: Driver[];
  total_pages: number;
  current_page: number;
  total_items: number;
}

const UserComponent: React.FC = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]); // State to hold drivers data
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Driver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [itemsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  // Debounced search
  const [debouncedSearch, setDebouncedSearch] = useState(searchQuery);

  

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Fetch user data
  const fetchUser = async (page: number, query: string) => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.getAllUserUrl(page, query);

    try {
      const response = await axios.get<ApiResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const driverData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setDrivers(driverData);
      setTotalPages(response.data.total_pages); // Set total pages for pagination
      setTotalItems(response.data.total_items);
      setCurrentPage(response.data.current_page);
    } catch (error) {
      setError("Failed to fetch drivers data. Please try again later.");
      console.error("Error fetching drivers data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch drivers whenever page or searchQuery changes
  useEffect(() => {
    fetchUser(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch]);

  // handle Menu
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSortSelection = (order: string) => {
    setSortOrder(order);
    handleCloseMenu();
    // Add your sorting logic here, e.g., API call with sort order key
  };

  // Reject modal
  const handleReject = async () => {
    if (!selectedUser) return;
    setIsDeleting(true);
    setDeleteError(null);

    const token = localStorage.getItem("authToken");

    try {
      await axios.delete(DatabaseApi.deleteUserUrl(), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          id: selectedUser.id,
        },
      });
      // Close the modal and refresh the data
      setShowRemoveModal(false);
      setSelectedUser(null);

      // Refresh the users list
      fetchUser(currentPage, searchQuery);
    } catch (error) {
      console.error("Error deleting user:", error);
      setDeleteError(
        // error.response?.data?.message ||
        "Failed to delete user. Please try again."
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDelete = () => {
    // Implement delete logic here
    console.log("Deleting user...");
    setShowRemoveModal(false);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  //
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Use slice only if drivers is an array
  const currentItems = Array.isArray(drivers)
    ? drivers.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "240px",
        marginTop: "40px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3 }}>
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h4"
            color="#2C3E50"
            style={{ fontFamily: "Montserrat" }}
          >
            Users
          </Typography>
          <div className="relative">
            <SearchIcon
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              style={{ width: "20px", height: "20px", color: "#FDA214", fontFamily: "Montserrat" }}
            />
            <Form.Control
              type="text"
              placeholder="Search Users by Name / Email / Phone No."
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                fontFamily: "Montserrat",
                width: "30rem",
                height: "3.5rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                border: "1px solid #d3d3d3",
              }} // Custom width and height// Additional rounding if needed
            />
            <style>
              {`
               .relative input::placeholder {
               color: #B4BDC4; // Placeholder color
                   }`}
            </style>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <Typography color="error" className="mb-4">
            {error}
          </Typography>
        )}

        {/* Loading State */}
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
              >
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Phone no.
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "30px",
                      fontWeight: "bold",
                      py: 1,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Date Joined
                    <IconButton onClick={handleOpenMenu} size="small">
                      <KeyboardArrowDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        onClick={() => handleSortSelection("Earliest")}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        Earliest
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSortSelection("Latest")}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        Latest
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((driver) => (
                  <TableRow
                    key={driver.id}
                    onClick={() => {
                      setSelectedUser(driver);
                      setShowModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        hoveredRow === driver.id ? "#f0f0f0" : "transparent",
                    }}
                    onMouseEnter={() => setHoveredRow(driver.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <TableCell
                      sx={{ py: 0.5, fontFamily: "Montserrat" }}
                    >{`${driver.first_name} ${driver.last_name}`}</TableCell>
                    <TableCell
                      sx={{
                        color: "#93310D",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textDecorationColor: "#93310D",
                        fontFamily: "Montserrat",
                        py: 0.5,
                      }}
                    >
                      {driver.phone}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      {driver.email}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      {new Date(driver.created_at)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .replace(/\//g, "-")
                        .toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      <Button
                        variant="link"
                        className="text-gray-500 hover:text-gray-700 p-1"
                        onClick={() => {
                          setSelectedUser(driver);
                          setShowModal(true);
                        }}
                      >
                        <PersonDash
                          className="ml-2"
                          style={{
                            width: "30px",
                            height: "30px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(drivers.length / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>
        {selectedUser && (
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="lg"
            style={{ fontFamily: "Montserrat" }}
          >
            <Box
              sx={{
                top: "50%",
                width: 820, // Adjust as per requirement
                maxHeight: "90vh",
                p: 3,
                borderRadius: 2,
              }}
            >
              <Modal.Header className="border-0 pb-0">
                <Modal.Title
                  className="w-100 text-left font-bold text-xl mb-4 ml-4"
                  style={{ fontFamily: "Montserrat" }}
                >
                  USER DETAILS
                </Modal.Title>
                <IconButton onClick={handleCloseModal} size="small">
                  <CloseIcon sx={{ color: "orange" }} />
                </IconButton>
              </Modal.Header>
              <Modal.Body className="pt-0 ml-4 mr-4">
                <div className="flex items-center mb-4">
                  <div className="w-20 h-20 rounded-full bg-gray-200 mr-4 overflow-hidden ml-4 ">
                    {/* <img
                  src="/placeholder.svg?height=80&width=80"
                  alt={user.name}
                  className="w-full h-full object-cover"
                /> */}
                  </div>
                  <h2 className="text-2xl font-semibold">
                    {" "}
                    {`${selectedUser.first_name} ${selectedUser.last_name}`}
                  </h2>
                </div>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-4 relative ">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Phone Number
                        </Form.Label>
                        <PhoneIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="tel"
                          value={selectedUser.phone}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem", // Space for the icon
                            width: "300px", // Reduce width here, adjust as needed
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Vehicle Type
                        </Form.Label>
                        <VehicleIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.vehicle_type || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          License Plate Number
                        </Form.Label>
                        <LicensePlateIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.license_plate_number || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription Start Date
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedUser.subscriptionStartDate || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Email
                        </Form.Label>
                        <EmailIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="email"
                          value={selectedUser.email}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Vehicle Color
                        </Form.Label>
                        <ColorIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.vehicle_color || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription Status
                        </Form.Label>
                        <StatusIcon
                          className="absolute left-2 bottom-3"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.account_status || ""}
                          readOnly
                          className={` ${
                            selectedUser.subscriptionStatus === "ACTIVE"
                              ? "text-green-600 font-semibold"
                              : ""
                          }`}
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription End Date
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedUser.subscriptionEndDate || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0  mb-4">
                <div className="w-full flex justify-center gap-28 px-4">
                  <Button
                    className="px-7 ml-28"
                    style={{
                      backgroundColor: "#961F16", // Set initial background color
                      color: "white", // Set text color
                      outline: "none",
                      border: "none",
                      fontFamily: "Montserrat",
                    }}
                    onMouseEnter={(e) => {
                      (e.target as HTMLButtonElement).style.backgroundColor =
                        "#7a1612"; // Darken color on hover
                    }}
                    onMouseLeave={(e) => {
                      (e.target as HTMLButtonElement).style.backgroundColor =
                        "#961F16"; // Revert to original color
                    }}
                    onClick={handleReject}
                  >
                    Remove User
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    className="px-14 py-2 mr-36 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    Close
                  </Button>
                </div>
              </Modal.Footer>
            </Box>
          </Modal>
        )}
        <Dialog
          open={showRemoveModal}
          onClose={handleCloseRemoveModal}
          aria-labelledby="remove-driver-dialog-title"
          PaperProps={{
            style: {
              borderRadius: "16px",
              padding: "24px",
              maxWidth: "600px",
              fontFamily: "Montserrat",
            },
          }}
        >
          {/* Close Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={handleCloseRemoveModal}
              sx={{
                minWidth: "auto",
                color: "#FDA214",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Modal Title */}
          <DialogTitle
            id="remove-driver-dialog-title"
            style={{
              fontWeight: "bold",
              color: "#122E59",
              textAlign: "center",
              paddingBottom: "8px",
              fontFamily: "Montserrat",
            }}
          >
            Remove Driver
          </DialogTitle>

          {/* Modal Content */}
          <DialogContent>
            <Typography
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "24px",
                fontFamily: "Montserrat",
              }}
            >
              Are you sure you want to delete this Driver account?
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "16px",
                fontSize: "18px",
                lineHeight: "1.5",
                fontFamily: "Montserrat",
              }}
            >
              The Driver will lose all their data and it will not be
              recoverable.
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "24px",
                fontSize: "18px",
                lineHeight: "1.5",
                fontFamily: "Montserrat",
              }}
            >
              To prevent this Driver from creating an account again with the
              same email or phone number, press "Delete & Block."
            </Typography>
          </DialogContent>

          {/* Modal Actions */}
          <DialogActions
            sx={{
              border: "none",
              padding: 0,
              marginTop: "32px",
              justifyContent: "center",
              gap: "16px",
              fontFamily: "Montserrat",
            }}
          >
            {/* Delete Account Button */}
            <Button
              onClick={handleDelete}
              style={{
                backgroundColor: "#FDA214",
                border: "none",
                color: "white",
                padding: "12px 24px",
                borderRadius: "24px",
                fontWeight: 500,
                minWidth: "160px",
                fontFamily: "Montserrat",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#e89412";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#FDA214";
              }}
            >
              Delete Account
            </Button>

            {/* Cancel Button */}
            <Button
              onClick={handleCloseRemoveModal}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #FDA214",
                color: "#FDA214",
                padding: "12px 24px",
                borderRadius: "24px",
                fontWeight: 500,
                minWidth: "160px",
                fontFamily: "Montserrat",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "rgba(253, 162, 20, 0.1)";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "transparent";
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};
export default UserComponent;
